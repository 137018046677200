$(function()
{
  $('.message.alert').dialog({modal:true});

  $(".monday-picker").datepicker(
    {
      dateFormat: "yy-mm-dd",
      showOn: 'both',
      numberOfMonths: 1,
      buttonImage: '/assets/icons/calendar_16x16.png',
      buttonImageOnly: true,
      beforeShowDay: function(dt)
      {
        monday = 1;
        allow_selection = [true, ""];
        prevent_selection = [false, ""];
        return (dt.getDay() == monday) ? allow_selection : prevent_selection
      }
    }
  );

  $(".date").not('.skip-datepicker').datepicker(
  {
    numberOfMonths: 2,
    showOn: 'both',
    buttonImage: '/assets/icons/calendar_16x16.png',
    buttonImageOnly: true,
    dateFormat: 'yy-mm-dd'
  });

  $('body').addClass('js');

  $('.buttonize').button();

  jQuery.ajaxSetup({ beforeSend: function(xhr) {xhr.setRequestHeader('Accept', 'text/javascript')} })

  jQuery.fn.preventDoubleSubmit = function() {
    this.submit(function() {
      $(this).find('input[type=submit]').attr('disabled', true);
      $(this).find('input[type=submit]').attr('value', 'wait...');
    })
  };

  jQuery.fn.submitWithAjax = function() {
    this.preventDoubleSubmit();

    this.submit(function() {
      $.post(this.action, $(this).serialize(), null, "script");
      return false;
    })
    return this;
  };

  function handle_ajax_link_error( response, s )
  {
    if( response.status == 404 )
    {
      alert('Sorry, that item has been deleted.');
    }
  }

  $(document).on('click', '.ajaxify', function(e)
  {
    e.preventDefault();
    $.ajax( $(this).attr('href'), {modal: true, error: handle_ajax_link_error } );
    return(false);
  });

  $('#placed_call_result').change(function()
  {
    $('#call_text_phoned_true').attr('checked', true)
  });

  $.hotkey('C', $APP.call_dialog);
  $.hotkey('R', $APP.route_dialog);
  $.hotkey(' ', $APP.search);
});

