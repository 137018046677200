// jsfiddle.net/dBR2r/8/

angular.module('Spinner', []).config(['$httpProvider', function($httpProvider) {
  $httpProvider.responseInterceptors.push('apiErrorInterceptor');
  var spinnerFunction = function(data, headersGetter) {
    // Start the spinner here
    $('#api-error').hide();
    $('#api-access').show();
    return data;
  };

  $httpProvider.defaults.transformRequest.push(spinnerFunction);
}]).factory('apiErrorInterceptor', ['$q', '$window', function($q, $window) {
  return function(promise) {
    return promise.then(function(response) {
      $('#api-access').hide();
      return response;
    }, function(response) {
      $(document).trigger('app.api_error', response);

      window.resp = response;
      if (response.status == 401) {
        window.location.href = '/';
      } else if (response.status == 403) {
        $('#api-error .message').html('You do not have permission to do that.');
      } else if (response.status == 422) {
        $('#api-error .message').html('Errors:');
        errors = [];
        if (window.resp.data && window.resp.data.errors) {
          errors = window.resp.data.errors;
        } else if (window.resp.data) {
          errors = window.resp.data;
        }
        $.each(errors, function(a, b) {
          $('#api-error .message').append("<li>" + a + " " + b + "</li>");
        });
      }
      $('#api-access').hide();
      $('#api-error').show();
      return $q.reject(response);
    });
  };
}]);

$(document).on('click', '.close-alert', function() {
  $(this).closest('.api-notice').hide();
});
