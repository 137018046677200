import React from 'react';
import PhoneNumberRow from './PhoneNumberRow';

export default class PhoneNumbersForm extends React.Component {
  constructor(props)
  {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.renderPhoneNumberRow = this.renderPhoneNumberRow.bind(this);
    this.possiblyAddNumbers = this.possiblyAddNumbers.bind(this);
  }

  possiblyAddNumbers()
  {
    let full = this.props.phone_numbers.every((pn)=>{return(pn.description && pn.description.length > 0)})

    if(full)
    {
      this.props.addPhoneNumber();
    }
  }

  handleChange(i)
  {
    return (change) => {
      this.props.onChange(
        [
          ...this.props.phone_numbers.slice(0, i),
          Object.assign(this.props.phone_numbers[i], change),
          ...this.props.phone_numbers.slice(i+1)
        ], this.possiblyAddNumbers
      )
    }
  }

  renderPhoneNumberRow(phone, i)
  {
    return(
      <PhoneNumberRow {...phone} key={i} onChange={this.handleChange(i)} />
    )
  }

  render()
  {
    return(
      <div>
        <legend>Phone Numbers</legend>
        <form className="form-inline">
        <table className="table table-striped table-condensed app-PhoneNumbers">
          <thead>
            <tr>
              <th>Description</th>
              <th>Number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.phone_numbers.map(this.renderPhoneNumberRow)}
          </tbody>
        </table>
        </form>
      </div>
    )
  }
}
