app.controller('EditRouteCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Technician', 'Route',
  function($scope, $routeParams, $location, fixRailsSerializer, Technician, Route ) {
    $scope.page_header = "Edit Route";

    Technician.query().then(
    function(resource){
      $scope.technicians = resource;
      load_route();
    });

    $scope.custom_origin = {description: 'Custom'};
    $scope.custom_destination = {description: 'Custom'};
    $(document).on('app.api_error', handleApiErrors.bind($scope));

    function handleApiErrors(_event, payload) {
      const scope = this;
      scope.route.has_overridable_errors = payload.data.has_overridable_errors;
    }

    function set_technician()
    {
      angular.forEach( $scope.technicians, function( tech )
      {
        if( tech.id == $scope.route.technician_id )
        {
          $scope.route.technician = tech;
        }
      })

      delete( $scope.route.technician_id );
    }

    function load_route()
    {
      Route.get( $routeParams['route_id'] ).then(
      function( resource )
      {
        $scope.route = resource;
        $scope.page_header = resource.name;
        set_technician();
        $scope.check_origin_and_destination();
        $scope.route.original_date = resource.date;
        $scope.route.original_technician_id = $scope.route.technician.id;
        $scope.route.has_overridable_errors = resource.validation_errors_overridable;
        $scope.route.override_validation = resource.validation_errors_overridable;
        $scope.route.override_reason = resource.validation_override_reason;
        $scope.route.validation_override_reason = resource.validation_override_reason;
        $scope.route.tech_managed = resource.tech_managed;
        $scope.route.skip_auto_confirmation_process = resource.skip_auto_confirmation_process;
      });
    };

    $scope.check_origin_and_destination = function()
    {
      found_origin = false;
      found_destination = false;

      angular.forEach( $scope.route.technician.locations, function(loc)
      {
        if( loc.location_id == $scope.route.origin.id )
        {
          $scope.route.origin = loc;
        	found_origin = true;
        }
      });

      angular.forEach( $scope.route.technician.locations, function(loc)
      {
        if( loc.location_id == $scope.route.destination.id )
        {
          $scope.route.destination = loc;
  	      found_destination = true;
        }
      });

      if( found_origin == false )
      {
        $scope.custom_origin = $scope.route.origin;
        $scope.custom_origin.description = 'Custom';
      }

      if( found_destination == false )
      {
        $scope.custom_destination = $scope.route.destination;
        $scope.custom_destination.description = 'Custom';
      }

      $scope.origin_options = $scope.route.technician.locations.concat([$scope.custom_origin]);
      $scope.destination_options = $scope.route.technician.locations.concat([$scope.custom_destination]);
    }

    $scope.change_technician = function()
    {
      $scope.origin_options = $scope.route.technician.locations.concat([$scope.custom_origin]);

      $scope.destination_options = $scope.route.technician.locations.concat([$scope.custom_destination]);

      if( $scope.route.origin != $scope.custom_origin )
      {
        $scope.route.origin = $scope.origin_options[0];
      }

      if( $scope.route.destination != $scope.custom_destination )
      {
        $scope.route.destination = $scope.destination_options[0];
      }
      $scope.updateRouteTimes();
      $scope.updateRouteFlags();
    }

    $scope.updateRouteFlags = function() {
      const route = $scope.route;
      const technician = route.technician;

      let techManagedRoute = false;
      let skipAutoConfirmationProcess = false;

      if (technician != undefined) {
        techManagedRoute = technician.techManagedRoutes || technician.tech_managed_routes;
        skipAutoConfirmationProcess = technician.skipAutoConfirmationProcess || technician.skip_auto_confirmation_process;
      }

      route.tech_managed = techManagedRoute;
      route.skip_auto_confirmation_process = skipAutoConfirmationProcess;
    }

    $scope.$watch('route.date', function(newValue, _oldValue){
      if (newValue) {
        $scope.updateRouteTimes();
      }
    });

    $scope.updateRouteTimes = function() {
      const route = $scope.route,
        technician = route.technician;

      if (technician == undefined) {
        return;
      }

      let startTime = technician.startTimeTimepicker,
          maxTime = technician.maxTime;

      const dateString = route.date, // in format 'yyy-mm-dd'
        scheduleProfile = technician.schedule_profile || technician.scheduleProfile;

      if (dateString && scheduleProfile) {
        const [year, month, day] = dateString.split(/[-\/]/),
          routeDate = new Date(year, month - 1, day),          // month '0' is january
          routeWeekDay = routeDate.getDay(),
          schedule = scheduleProfile.attributes.schedule,
          routeDaysSchedule = schedule[routeWeekDay];

        if (routeDaysSchedule) {
          startTime = routeDaysSchedule.startTime;
          maxTime = routeDaysSchedule.maxTime;
        }
      }

      // update fields if the technician is different
      if (route.original_technician_id != technician.id || route.date != route.original_date) {
        route.has_overridable_errors = undefined;
        route.override_validation = undefined;
        route.override_reason = undefined;
        route.validation_override_reason = undefined
        route.start_time = startTime;
        route.max_time = maxTime
      }
    }

    $scope.persist = function()
    {
      function visit_route_page(response)
      {
        window.location = "/routes/" + response.id;
      }

      if( $scope.route.origin.description == "Custom" )
      {
        $scope.route.origin_attributes =
        {
          street1: $scope.route.origin.street1,
        	street2: $scope.route.origin.street2,
        	city: $scope.route.origin.city,
        	state: $scope.route.origin.state,
        	zipcode: $scope.route.origin.zipcode
        }
      }
      else
      {
        $scope.route.origin_id = $scope.route.origin.location_id;
        $scope.route.origin_attributes = null
      }

      if( $scope.route.destination.description == "Custom" )
      {
        $scope.route.destination_attributes =
        {
          street1: $scope.route.destination.street1,
        	street2: $scope.route.destination.street2,
        	city: $scope.route.destination.city,
        	state: $scope.route.destination.state,
        	zipcode: $scope.route.destination.zipcode
        }
      }
      else
      {
        $scope.route.destination_id = $scope.route.destination.location_id;
        $scope.route.destination_attributes = null
      }
      $scope.route.awesome = undefined;
      $scope.route.center = undefined;
      $scope.route.local_time = undefined;
      $scope.route.validation_errors_overridable = undefined;

      if (!$scope.route.override_validation) {
        $scope.route.validation_override_reason = '';
      }

      $scope.route.has_overridable_errors = undefined;
      $scope.route.override_reason = undefined;
      $scope.route.original_technician_id = undefined;
      $scope.route.original_date = undefined;
      $scope.route.update().then( visit_route_page )
    }

    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];
  }
]);
