//Subclass of AbstractServiceOrderCtrl
//I know it isn't inheritance, it is composition.
//But who cares in JavaScript:)
app.controller('NewServiceOrderCtrl', [
  '$scope', '$controller', '$routeParams', '$location', 'fixRailsSerializer', 'Customer', 'Client', 'Company', 'ServiceOrder', 'WorkTimeEstimate', 'PickupLocation',
  function($scope, $controller, $routeParams, $location, fixRailsSerializer, Customer, Client, Company, ServiceOrder, WorkTimeEstimate, PickupLocation) {
    $controller('AbstractServiceOrderCtrl', { $scope, $routeParams, $location, fixRailsSerializer, Customer, Company, Client, ServiceOrder, WorkTimeEstimate });

    $scope.store_id = document.current_user_store_id;
    $scope.isClient = document.getElementById('is_client').value == 'true';

    $scope.store_rep = $scope.store_id != undefined;

    Company.query().then(function(list) {
      $scope.companies = list;
    })

    Client.query().then(function(list)
    {
      $scope.clients = list;

      if($scope.service_order.client_id)
      {
        angular.forEach($scope.clients, function(needle)
        {
          if( needle.id == $scope.service_order.client_id )
          {
            $scope.service_order.client = needle;
          }
        })
      }

      if($scope.store_rep)
      {
        $scope.service_order.client = list[0]
      }
    })

    $scope.pickup_locations = PickupLocation.query();

    $scope.service_order = {customer_id: $routeParams['customer_id'], client_id: $routeParams['client_id'], progenitor_type: $routeParams['progenitor_type'], progenitor_id: $routeParams['progenitor_id'], episode: 1, bill_strategy: 'Normal'};

    $scope.$watch('service_order.client', change_client);

    function change_client( current, previous )
    {
      if(current === undefined)
      {
        $scope.bill_strategies = [];
      }
      else
      {
        $scope.service_order.bill_strategy = 'Normal';
        $scope.bill_strategies = ['Normal', 'Recall'];
        suggest_store(current.id)
      }
    }

    function change_assigned_company( current, previous )
    {
        $scope.service_order.assigned_company = current;
    }

    function change_billing_company( current, previous )
    {
        $scope.service_order.billing_company = current;
    }

    function suggest_store( client_id )
    {
      angular.forEach($scope.local_stores, function(needle)
        {
          if( needle.client_id == client_id )
          {
            $scope.service_order.store_id = needle.id;
          }
        })
    }

    function load_customer()
    {
      Customer.$get('/customers/' + $routeParams['customer_id'] + '/serviceable.json').then(function(response){
        $scope.service_order.customer = response;

        if(response.local_stores.length > 0)
        {
          $scope.local_stores = response.local_stores;
        }
      });
    }

    $scope.$watch('service_order', load_customer);

    $scope.save_service_order = function()
    {
      function disable_submit()
      {
        $('button[data-loading-text]').button('loading');
      }

      function enable_submit()
      {
        $('button[data-loading-text]').button('reset');
      }

      function visit_service_order_items_page(service_order)
      {
        $location.path('/service_orders/' + service_order.id + '/items');
      }

      var service_order = new ServiceOrder($scope.service_order);
      service_order.client_id = service_order.client.id
      delete service_order.client

      disable_submit();
      service_order.create().then( visit_service_order_items_page, enable_submit )
    }
  }
]);
