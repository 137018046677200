let APP = APP || {}
APP.filter = APP.filter || {}

filterWithDelay = function(seconds) {
  var delay = seconds * 1000; // Transform in milliseconds

  if (APP.filter.delayTimeout) {
    clearTimeout(APP.filter.delayTimeout);
  }

  if (delay > 0) {
    APP.filter.delayTimeout = setTimeout(filter, delay);
  } else {
    filter();
  }
}

filter = function () {
  $('.ajax-loader').removeClass('display-none');

  var data = buildData();
  window.location.search = data.toString();
}

buildData = function () {
  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);

  $('[data-filter] [data-filter-trigger]').each(function (index, element) {
    var tag = $(element).data('filter-name');
    var value = $(element).val().trim();

    urlParams.set(tag, value);
    urlParams.set('iDisplayStart', 1);
  })

  return urlParams;
}

cleanUpFilterField = function (input) {
  return new Promise(function(resolve, reject) {
    let oldValue = $(input).data('old-value');

    if (typeof oldValue === 'undefined') {
      oldValue = '';
    }

    let newValue = $(input).val();
    let pattern = $(input).data('filter-pattern');

    if (pattern == 'numbers-only') {
      newValue = newValue.replace(/[^0-9]/g, '');
      input.value = newValue;

      if (oldValue !== newValue) {
        $(input).data('old-value', newValue);
        resolve();
      } else {
        reject();
      }
    } else {
      $(input).data('old-value', newValue);
      resolve();
    }
  })
}

$(document).ready(function () {
  $(document).on('keydown', '[data-filter] input[type="text"][data-filter-trigger]', function (event) {
    if(event.key === 'Enter') {
      filterWithDelay(0);
    }
  });

  $(document).on('keyup', '[data-filter] [data-filter-trigger]', function (event) {
    cleanUpFilterField(event.target).then(function () {
      let delay = $(event.target).data('filter-delay');

      if (typeof delay === 'undefined') {
        delay = 1;
      }

      filterWithDelay(delay);
    }, function () {});
  })

  $(document).on('change', '[data-filter] [data-filter-trigger]', function (event) {
    cleanUpFilterField(event.target).then(function () {
      filterWithDelay(0);
    }, function () {});
  })
})
