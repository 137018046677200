var APP = APP || {}
APP.partsRequestsItems = APP.partsRequestsItems || {}
APP.partsRequestsItems.technicianHangouts = APP.partsRequestsItems.technicianHangouts || []
APP.partsRequestsItems.functions = {
  //
  // Table functions
  //

  initializeTooltips: function () {
    $("[data-parts-requests-item-table-container] [data-toggle=tooltip]").tooltip();
  },

  hideActions: function (elem) {
    var id = $(elem).attr('data-parts-requests-item-id');
    var table = $(elem).closest('table');

    $('[data-parts-requests-item-actions-row=' + id + ']', table).addClass('display-none');
    $(elem).html('Show Actions');
    $(elem).attr('data-parts-requests-item-actions-opened', false);
  },

  showActions: function (elem) {
    var id = $(elem).attr('data-parts-requests-item-id');
    var table = $(elem).closest('table');

    $('[data-parts-requests-item-actions-row=' + id + ']', table).removeClass('display-none');
    $(elem).html('Hide Actions');
    $(elem).attr('data-parts-requests-item-actions-opened', true);
  },

  reloadList: function (elem) {
    var batch = $('[data-parts-requests-item-actions-row]').attr('data-batch');

    if (batch !== 'true') {
      let container = $(elem).closest('[data-parts-requests-item-reload-url]');
      let url = container.data('parts-requests-item-reload-url');
      let actionsUrl = container.data('parts-request-reload-actions-url');

      id = container.data('parts-request-id');

      $.ajax({
        url: url,
        data: {
          format: 'js'
        }
      }).done(function () {
        $.ajax({
          url: '/notes',
          data: {
            notable_type: 'PartsRequest',
            notable_id: id,
            container: '.parts-request-container .notes',
            format: 'js'
          }
        }).done(function () {
          $.ajax({
            url: actionsUrl,
            data: {
              format: 'js'
            }
          }).done(function () {
            APP.partsRequestsItems.functions.updateBatchBoxVisibility();
            APP.partsRequestsItems.functions.initializeTooltips();
            APP.partsRequestsItems.functions.hideLoading();
          })
        })
      })
    }
  },

  prepareToSendRequest: function () {
    $('.parts-requests-item-alert').addClass('display-none');
    $('.parts-requests-item-alert .message-body').html(null);
    APP.partsRequestsItems.functions.showLoading();
  },

  showError: function (errors) {
    $.each(errors, function (idx, error) {
      var message = document.createElement('div');
      $(message).html(error);
      $('.parts-requests-item-alert .message-body').append(message);
    })

    $('.parts-requests-item-alert').removeClass('display-none');
    APP.partsRequestsItems.functions.hideLoading();
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".parts-requests-item-alert").offset().top - 100
    }, 1000);
  },

  checkLocationAttrs: function () {
    let container = $('#parts-requests-location-modal');

    let id = $('[data-location-attr=id]', container).val().trim();
    let zipcode = $('[data-location-attr=zipcode]', container).val().trim();
    let street1 = $('[data-location-attr=street1]', container).val().trim();
    let city = $('[data-location-attr=city]', container).val().trim();
    let state = $('[data-location-attr=state]', container).val().trim();

    let validZipcode = zipcode.length == 5;
    let validStreet1 = street1.length > 0;
    let validCity = city.length > 0;
    let validState = state.length === 2;

    let confirmButton = $('#parts-requests-location-modal [data-proceed]');

    if (validStreet1 && validZipcode && validCity && validState) {
      confirmButton.attr("disabled", false);
    } else {
      confirmButton.attr("disabled", true);
    }
  },

  populateTechnicianHangouts: function () {
    select = $('[data-location-technician-hangouts-select]');
    select.empty();

    var option = document.createElement('option');
    option.disabled = 'disabled';
    option.selected = 'selected';
    option.innerHTML = "Select one";
    select.append(option);

    $.each(APP.partsRequestsItems.technicianHangouts, function (index, hangout) {
      var option = document.createElement('option');
      option.setAttribute('data-zipcode', hangout.location.zipcode);
      option.setAttribute('data-street1', hangout.location.street1);
      option.setAttribute('data-street2', hangout.location.street2);
      option.setAttribute('data-city', hangout.location.city);
      option.setAttribute('data-state', hangout.location.state);
      option.setAttribute('data-cross_street', hangout.location.cross_street);
      option.innerHTML = hangout.full_address;
      option.value = hangout.id;

      select.append(option);
    })
  },

  loadTechnicianHangouts: function (technicianId) {
    return new Promise(function(resolve, reject) {
      if (technicianId == '') {
        APP.partsRequestsItems.technicianHangouts = [];
        return resolve();
      }

      if (APP.partsRequestsItems.technicianHangouts.length > 0) {
        return resolve();
      }

      $.ajax({
        type: "GET",
        url: '/employees/' + technicianId + '/hangouts.json',
        dataType: "JSON",
        success: function(data) {
          APP.partsRequestsItems.technicianHangouts = data.hangouts;
          return resolve();
        },
        error: function(error) {
          return reject(error);
        }
      });
    })
  },

  showLoading: function () {
    $('.ajax-loader').removeClass('display-none');
  },

  hideLoading: function () {
    $('.ajax-loader').addClass('display-none');
  },

  showAddressContainer: function (reference) {
    var container = $('[data-location-container="' + reference + '"]');

    $('[data-location-container]').addClass('display-none');
    $(container).removeClass('display-none');
  },
  //
  // Table functions
  //

  //
  // Batch functions
  //
  unselectAll: function () {
    $('[data-parts-requests-item-actions="batch"]:visible:checked').click();
  },

  selectAll: function () {
    $('[data-parts-requests-item-actions="batch"]:visible:not(:checked)').click();
  },

  updateSelectAllCheckbox: function () {
    var notChecked = $('[data-parts-requests-item-actions="batch"]:visible:not(:checked)');

    if (notChecked.length == 0) {
      $('[data-parts-requests-item-actions="select-all"]').prop('checked', true);
    } else {
      $('[data-parts-requests-item-actions="select-all"]').prop('checked', false);
    }
  },

  updateCheckedQuantity: function () {
    var quantity = $('[data-parts-requests-item-actions="batch"]:visible:checked').length;
    $('[data-parts-requests-item-batch-quantity]').html(quantity);
  },

  updateBatchActions: function () {
    var checkeds = $('[data-parts-requests-item-actions="batch"]:checked');
    var statuses = []

    checkeds.each(function(index, element) {
      var status = $(element).attr('data-status');
      if (!statuses.includes(status)) {
        statuses.push(status);
      }
    });

    var origins = []
    checkeds.each(function(index, element) {
      var origin = $(element).attr('data-origin');
      if (!origins.includes(origin)) {
        origins.push(origin);
      }
    });

    var parentStatus = $('#parts-request-status').val();

    $('[data-parts-requests-item-batch-action-trigger]').each(function(index, element) {
      var checkboxStatuses = JSON.parse($(element).attr('data-parts-requests-item-batch-action-allowed-by-statuses'));
      var checkboxParentStatuses = JSON.parse($(element).attr('data-parts-request-batch-action-allowed-by-statuses'));
      var checkboxOriginRequired = $(element).attr('data-parts-requests-item-batch-action-origin') === 'required';

      var validStatus = true;
      var validOrigin = true;

      remainingStatuses = statuses.filter( ( el ) => !checkboxStatuses.includes( el ) );
      if (remainingStatuses.length > 0) {
        validStatus = false;
      }

      if (checkboxOriginRequired && origins.includes('null')) {
        validOrigin = false;
      }

      if (validStatus && checkboxParentStatuses.includes(parentStatus) && validOrigin) {
        $(element).removeClass('display-none');
      } else {
        $(element).addClass('display-none');
      }
    });
  },

  updateBatchBoxVisibility: function () {
    var box = $('[data-parts-requests-item-batch-box]');
    var checked = $('[data-parts-requests-item-actions="batch"]:checked');
    var availableButtons = $(".actions button", box);

    if (availableButtons.length > 0 && checked.length > 0) {
      box.removeClass('display-none');
    } else {
      box.addClass('display-none');
    }
  }
  //
  // Batch functions
  //
}

$(document).ready(function () {
  $(document).on('click', '[data-technician-location="open"]', function (event) {
    APP.partsRequestsItems.functions.showAddressContainer('technician');
  })

  $(document).on('click', '[data-technician-location="close"]', function (event) {
    APP.partsRequestsItems.functions.showAddressContainer('custom');
  })

  $(document).on('click', '[data-parts-requests-item-actions=true]', function (event) {
    event.preventDefault();
    var elem = event.target;

    $('[data-parts-requests-item-actions-opened=true]').each(function(index, otherElem) {
      var id = $(elem).attr('data-parts-requests-item-id');
      var otherId = $(otherElem).attr('data-parts-requests-item-id');

      if (id != otherId) {
        APP.partsRequestsItems.functions.hideActions(otherElem);
      }
    });

    if($(elem).attr('data-parts-requests-item-actions-opened') == 'true') {
      APP.partsRequestsItems.functions.hideActions(elem);
    } else {
      APP.partsRequestsItems.functions.showActions(elem);
    }
  });

  $(document).on('ajax:beforeSend', '[data-parts-requests-item-actions-row]', function (event) {
    APP.partsRequestsItems.functions.prepareToSendRequest();
  });

  $(document).on('ajax:error', '[data-parts-requests-item-actions-row]', function (event, jqXHR) {
    APP.partsRequestsItems.functions.showError(JSON.parse(jqXHR.responseText).errors);
  });

  $(document).on('ajax:success', '[data-parts-requests-item-actions-row]', function (event) {
    APP.partsRequestsItems.functions.reloadList(event.target);
  });

  $(document).on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
    let identifier = new Date().getTime();
    insertedItem.attr('data-parts-requests-items-identifier', identifier);
  });

  $(document).on('cocoon:after-insert', function(e) {
    $('[data-select=2]').select2();
  });

  $(document).on('click', '#parts-requests-items .remove-location', function (event) {
    let target = event.target;
    let container = target.closest('.location-container');

    let id = $('[data-location-attr=id]', container);
    let zipcode = $('[data-location-attr=zipcode]', container);
    let street1 = $('[data-location-attr=street1]', container);
    let street2 = $('[data-location-attr=street2]', container);
    let city = $('[data-location-attr=city]', container);
    let state = $('[data-location-attr=state]', container);
    let destroy = $('[data-location-attr=_destroy]', container);

    let fake = $('[name=fake_location]', container);

    zipcode.val('');
    street1.val('');
    street2.val('');
    city.val('');
    state.val('');
    destroy.val(true);
    fake.val('');
  })

  // File: app/views/parts_requests/_form.html.haml
  $(document).on('change', '[data-employee-select]', function () {
    APP.partsRequestsItems.technicianHangouts = [];
  })

  $(document).on('shown', '#parts-requests-location-modal', function (event) {
    let modalBatch = $('[data-location-attr=batch]', '#parts-requests-location-modal');
    $('.modal-body', event.target).scrollTop(0);
  });

  $(document).on('click', '[data-location="batch"]', function () {
    let technicianId = $('#parts_request_employee_id');

    let modalId = $('[data-location-attr=id]', '#parts-requests-location-modal');
    let modalZipcode = $('[data-location-attr=zipcode]', '#parts-requests-location-modal');
    let modalStreet1 = $('[data-location-attr=street1]', '#parts-requests-location-modal');
    let modalStreet2 = $('[data-location-attr=street2]', '#parts-requests-location-modal');
    let modalCity = $('[data-location-attr=city]', '#parts-requests-location-modal');
    let modalState = $('[data-location-attr=state]', '#parts-requests-location-modal');
    let modalCrossStreet = $('[data-location-attr=cross-street]', '#parts-requests-location-modal');
    let modalIdentifier = $('[data-location-identifier]', '#parts-requests-location-modal');
    let modalBatch = $('[data-location-attr=batch]', '#parts-requests-location-modal');

    modalId.val('');
    modalZipcode.val('');
    modalStreet1.val('');
    modalStreet2.val('');
    modalCity.val('');
    modalState.val('');
    modalIdentifier.val('');
    modalBatch.val(true);

    APP.partsRequestsItems.functions.showLoading();
    APP.partsRequestsItems.functions.loadTechnicianHangouts(technicianId.val()).then(function () {
      APP.partsRequestsItems.functions.populateTechnicianHangouts()

      if (APP.partsRequestsItems.technicianHangouts.length > 0) {
        $('[data-technician-location="true"]').removeClass('display-none');
      } else {
        $('[data-technician-location="true"]').addClass('display-none');
      }

      APP.partsRequestsItems.functions.showAddressContainer('custom');
      APP.partsRequestsItems.functions.checkLocationAttrs();

      $('[data-location-batch-alert]').removeClass('display-none');
      $('#parts-requests-location-modal').modal('show')
      APP.partsRequestsItems.functions.hideLoading();
    }, function (error) {
      alert(error);
      APP.partsRequestsItems.functions.hideLoading();
    });
  })

  $(document).on('click', '#parts-requests-items .add-location', function (event) {
    let target = event.target;
    let nestedFields = target.closest('.nested-fields');

    let identifier = $(nestedFields).attr('data-parts-requests-items-identifier');

    let id = $('[data-location-attr=id]', nestedFields);
    let zipcode = $('[data-location-attr=zipcode]', nestedFields);
    let street1 = $('[data-location-attr=street1]', nestedFields);
    let street2 = $('[data-location-attr=street2]', nestedFields);
    let city = $('[data-location-attr=city]', nestedFields);
    let state = $('[data-location-attr=state]', nestedFields);
    let technicianId = $('#parts_request_employee_id');

    let modalId = $('[data-location-attr=id]', '#parts-requests-location-modal');
    let modalZipcode = $('[data-location-attr=zipcode]', '#parts-requests-location-modal');
    let modalStreet1 = $('[data-location-attr=street1]', '#parts-requests-location-modal');
    let modalStreet2 = $('[data-location-attr=street2]', '#parts-requests-location-modal');
    let modalCity = $('[data-location-attr=city]', '#parts-requests-location-modal');
    let modalState = $('[data-location-attr=state]', '#parts-requests-location-modal');
    let modalIdentifier = $('[data-location-identifier]', '#parts-requests-location-modal');
    let modalBatch = $('[data-location-attr=batch]', '#parts-requests-location-modal');

    modalId.val(id.val());
    modalZipcode.val(zipcode.val());
    modalStreet1.val(street1.val());
    modalStreet2.val(street2.val());
    modalCity.val(city.val());
    modalState.val(state.val());
    modalIdentifier.val(identifier);
    modalBatch.val(false);

    APP.partsRequestsItems.functions.showLoading();
    APP.partsRequestsItems.functions.loadTechnicianHangouts(technicianId.val()).then(function () {
      APP.partsRequestsItems.functions.populateTechnicianHangouts()

      if (APP.partsRequestsItems.technicianHangouts.length > 0) {
        $('[data-technician-location="true"]').removeClass('display-none');
      } else {
        $('[data-technician-location="true"]').addClass('display-none');
      }

      APP.partsRequestsItems.functions.showAddressContainer('custom');
      APP.partsRequestsItems.functions.checkLocationAttrs();

      $('[data-location-batch-alert]').addClass('display-none');
      $('#parts-requests-location-modal').modal('show');
      APP.partsRequestsItems.functions.hideLoading();
    }, function (error) {
      alert('Something went wrong while loading the Locations. Please, try again.');
      APP.partsRequestsItems.functions.hideLoading();
    });
  })

  $(document).on('keyup', '#parts-requests-location-modal [data-location-attr]', function (e) {
    APP.partsRequestsItems.functions.checkLocationAttrs();
  })

  $(document).on('change', '[data-location-technician-hangouts-select]', function (e) {
    var target = $(e.target);
    var option = $("option:selected", target);

    let dataZipcode = option.attr('data-zipcode') || '';
    let dataStreet1 = option.attr('data-street1') || '';
    let dataStreet2 = option.attr('data-street2') || '';
    let dataCity = option.attr('data-city') || '';
    let dataState = option.attr('data-state') || '';
    let dataCrossStreet = option.attr('data-cross-street') || '';

    let modalZipcode = $('[data-location-attr=zipcode]', '#parts-requests-location-modal');
    let modalStreet1 = $('[data-location-attr=street1]', '#parts-requests-location-modal');
    let modalStreet2 = $('[data-location-attr=street2]', '#parts-requests-location-modal');
    let modalCity = $('[data-location-attr=city]', '#parts-requests-location-modal');
    let modalState = $('[data-location-attr=state]', '#parts-requests-location-modal');
    let modalCrossStreet = $('[data-location-attr=cross-street]', '#parts-requests-location-modal');
    let modalIdentifier = $('[data-location-identifier]', '#parts-requests-location-modal');

    dataZipcode = dataZipcode.toString().replace(/^null$/, '');
    dataStreet1 = dataStreet1.toString().replace(/^null$/, '');
    dataStreet2 = dataStreet2.toString().replace(/^null$/, '');
    dataCity = dataCity.toString().replace(/^null$/, '');
    dataState = dataState.toString().replace(/^null$/, '');
    dataCrossStreet = dataCrossStreet.toString().replace(/^null$/, '');

    modalZipcode.val(dataZipcode);
    modalStreet1.val(dataStreet1);
    modalStreet2.val(dataStreet2);
    modalCity.val(dataCity);
    modalState.val(dataState.toUpperCase());
    modalCrossStreet.val(dataCrossStreet);

    $('[data-radio-location="custom"]').prop('checked', true);

    APP.partsRequestsItems.functions.showAddressContainer('custom');
    APP.partsRequestsItems.functions.checkLocationAttrs();
  })

  $(document).on('click', '#parts-requests-location-modal [data-proceed]', function () {
    let modalId = $('[data-location-attr=id]', '#parts-requests-location-modal');
    let modalZipcode = $('[data-location-attr=zipcode]', '#parts-requests-location-modal');
    let modalStreet1 = $('[data-location-attr=street1]', '#parts-requests-location-modal');
    let modalStreet2 = $('[data-location-attr=street2]', '#parts-requests-location-modal');
    let modalCity = $('[data-location-attr=city]', '#parts-requests-location-modal');
    let modalState = $('[data-location-attr=state]', '#parts-requests-location-modal');
    let modalCrossStreet = $('[data-location-attr=cross-street]', '#parts-requests-location-modal');
    let modalIdentifier = $('[data-location-identifier]', '#parts-requests-location-modal');
    let modalBatch = $('[data-location-attr=batch]', '#parts-requests-location-modal');

    let identifier = modalIdentifier.val();
    let nestedFields = null;

    if (modalBatch.val() === 'true') {
      nestedFields = $('#parts-requests-items');
    } else {
      nestedFields = $('[data-parts-requests-items-identifier="' + identifier + '"]').closest('.nested-fields');
    }

    let id = $('[data-location-attr=id]', nestedFields);
    let zipcode = $('[data-location-attr=zipcode]', nestedFields);
    let street1 = $('[data-location-attr=street1]', nestedFields);
    let street2 = $('[data-location-attr=street2]', nestedFields);
    let city = $('[data-location-attr=city]', nestedFields);
    let state = $('[data-location-attr=state]', nestedFields);
    let crossStreet = $('[data-location-attr=cross-street]', nestedFields);
    let destroy = $('[data-location-attr=_destroy]', nestedFields);
    let fake = $('[name=fake_location]', nestedFields);

    let idVal = modalId.val().trim();
    let zipcodeVal = modalZipcode.val().trim();
    let street1Val = modalStreet1.val().trim();
    let street2Val = modalStreet2.val().trim();
    let cityVal = modalCity.val().trim();
    let stateVal = modalState.val().trim();
    let crossStreetVal = modalCrossStreet.val().trim();

    id.val(idVal);
    zipcode.val(zipcodeVal);
    street1.val(street1Val);
    street2.val(street2Val);
    city.val(cityVal);
    state.val(stateVal);
    crossStreet.val(crossStreetVal);
    destroy.val(false);

    let fakeVal = [street1Val, street2Val, cityVal, stateVal, crossStreetVal].filter(function (v) { return v !== '' }).join(', ');
    fakeVal = [fakeVal, zipcodeVal].filter(function (v) { return v !== '' }).join(' ');
    fake.val(fakeVal);

    $('#parts-requests-location-modal').modal('hide');
  })

  $(document).on('change', '[data-parts-requests-item-select-origin]', function (e) {
    e.preventDefault();

    let form = $(e.target).closest('form');
    var valuesToSubmit = $(form).serialize();

    $.ajax({
      type: "PUT",
      url: $(form).attr('action'),
      data: valuesToSubmit,
      dataType: "JSON",
      beforeSend: function() {
        APP.partsRequestsItems.functions.prepareToSendRequest();
      },
      success: function() {
        APP.partsRequestsItems.functions.reloadList(form);
      },
      error: function(jqXHR) {
        APP.partsRequestsItems.functions.showError(JSON.parse(jqXHR.responseText).errors);
      }
    });
  })

  APP.partsRequestsItems.functions.initializeTooltips();
});

$(document).ready(function () {
  $(document).on('click', '[data-parts-requests-item-actions=batch]', function (event) {
    var target = $(event.target);

    APP.partsRequestsItems.functions.updateSelectAllCheckbox();
    APP.partsRequestsItems.functions.updateBatchActions();

    APP.partsRequestsItems.functions.updateCheckedQuantity();
    APP.partsRequestsItems.functions.updateBatchBoxVisibility();
  });

  $(document).on('click', '[data-parts-requests-item-actions="select-all"]', function (event) {
    var target = $(event.target);

    if (target.prop('checked')) {
      APP.partsRequestsItems.functions.selectAll();
    } else {
      APP.partsRequestsItems.functions.unselectAll()
    }
  })

  $(document).on('click', '[data-parts-requests-item-batch-action-trigger]', function (event) {
    var target = $(event.target);
    var confirmMessage = $(target).attr('data-confirm-message');

    if (!confirmMessage || confirm(confirmMessage)) {
      var method = $(target).attr('data-parts-requests-item-batch-action-method');
      var checkeds = $('[data-parts-requests-item-actions="batch"]:checked');

      // Setting this attibute to avoid reloading the list befor all updates have been finished.
      $('[data-parts-requests-item-actions-row]').attr('data-batch', true);
      APP.partsRequestsItems.functions.showLoading();

      checkeds.each(function (index, element) {
        var row = $(element).closest('[data-parts-requests-item-row]');
        var id = row.attr('data-parts-requests-item-row');
        var actions = $('[data-parts-requests-item-actions-row="' + id + '"]');
        var button = $('[data-parts-requests-item-method="' + method + '"]', actions);

        if (index == checkeds.length - 1) {
          $('[data-parts-requests-item-actions-row]').attr('data-batch', false);
        }

        button.data('confirm', null);
        button.click();
      })
    }
  });
});

//
// Batch
//
