var APP = APP || {}
APP.users = APP.users || {}

APP.users.functions = {
  hideActions: function(elem) {
    var id = $(elem).attr('data-user-id');
    var table = $(elem).closest('table');

    $('[data-user-actions-row=' + id + ']', table).addClass('display-none');
    $(elem).html('Show Actions');
    $(elem).attr('data-user-actions-opened', false);
  },

  showActions: function(elem) {
    var id = $(elem).attr('data-user-id');
    var table = $(elem).closest('table');

    $('[data-user-actions-row=' + id + ']', table).removeClass('display-none');
    $(elem).html('Hide Actions');
    $(elem).attr('data-user-actions-opened', true);
  }
}

$(document).ready(function () {
  $(document).on('click', '[data-user-actions=true]', function (event) {
    event.preventDefault();
    var elem = event.target;

    $('[data-user-actions-opened=true]').each(function(index, otherElem) {
      var id = $(elem).attr('data-user-id');
      var otherId = $(otherElem).attr('data-user-id');

      if (id != otherId) {
        APP.users.functions.hideActions(otherElem);
      }
    });

    if($(elem).attr('data-user-actions-opened') == 'true') {
      APP.users.functions.hideActions(elem);
    } else {
      APP.users.functions.showActions(elem);
    }
  });
});
