import React from 'react';

export default (props) => {
  return(
    <div className="app-Loading">
      <h1>Loading...</h1>
    </div>
  );
}

