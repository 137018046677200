app.factory('jsonURLInterceptor', function() {
  return {
    'beforeRequest': function (httpConfig) {
      if (httpConfig.url.match(/\.json$/) == null) {
        if (httpConfig.url.match(/\?/)) {
          httpConfig.url = httpConfig.url.replace('?', '.json?')
        } else {
          httpConfig.url += '.json';
        }
      }
      return httpConfig;
    }
  };
});

app.factory('deletePhoneInterceptor', function() {
  return {
    'beforeRequestWrapping': function (httpConfig, resourceConstructor, context) {
      if (httpConfig.method != 'get') {
        angular.forEach(httpConfig.data.phone_numbers_attributes, function(phone) {
          if (phone.description == "" && phone.number == "") {
            phone._destroy = true;
          }
        });
      }
      return httpConfig;
    }
  };
});

app.factory('deleteIssueInterceptor', function() {
  return {
    'beforeRequestWrapping': function (httpConfig, resourceConstructor, context) {
      if ( (httpConfig.method != 'get') && (httpConfig.data != undefined) ) {
        angular.forEach(httpConfig.data.issues_attributes, function(issue) {
          if (issue.description == "") {
            issue._destroy = true;
          }
        });
      }
      return httpConfig;
    }
  };
});
