/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

global.$ = require("jquery")
global.$APP = {};

import "channels"

import 'jquery-ui-1.12.1.min';
import 'sortablejs/Sortable.min';

import 'twitter/bootstrap';
import Rails from '@rails/ujs';
Rails.start();

import * as _ from 'underscore-1.8.3';
import 'app.initialize';
import 'popper.js-2.11.8.min';
import 'moment/moment';
import 'angularjs/angular'
import 'angularjs/rails/resource/index'
import 'angularjs/rails/resource/utils/inflector'
import 'angularjs/rails/resource/utils/injector'
import 'angularjs/rails/resource/utils/url_builder'
import 'angularjs/rails/resource/serialization'
import 'angularjs/rails/resource/resource'
import 'angular-ui-bootstrap-tpls';
import '@claviska/jquery-minicolors/jquery.minicolors.min';
import 'select2/dist/js/select2.min';
import 'leaflet';
import 'mapquest-1.3.2';
import 'bootstrap-datepicker-core-1.8.0';
import 'datatables/jquery.datatables-1.10.10';
import 'datatables/jquery.bootstrap-2-jquery-datatables-bootstrap';
import 'jquery.hotkeys';
import 'app.hotkeys';
import 'sortable';
import 'checkable';
import 'todo';
import 'activity';
import 'special-day';
import 'text-message';
import 'part';
import 'timelog';
import 'user';
import 'filter';
import 'jquery.sticky-table-headers';
import 'fontawesome';
import 'jquery.businessHours.js';
import 'select2.min';
import 'select2-init';
import 'parts-request';
import 'parts-requests-item';
import 'technicians-part';
import 'replacement-part';
import 'cocoon';
import 'custom-cocoon';
import 'daterangepicker.min';
import 'daterangepicker-input';
import 'custom-app';
import 'dropzone';
import 'customer-acknowledgement';
import 'v2/application';
import 'v2/jquery.hotkeys';
import 'v2/jquery.swift';
import 'heatmap';
import 'heatmap-gmaps';
import 'timeframes';
import 'chat';
import 'departure';

$APP.busy = function() {
    $("#ajax_busy").dialog({modal: true});
  }

$APP.ready = function() {
    $("#ajax_busy").dialog('close');
  }

$APP.call_dialog = function() {
  $('.modal').dialog('close');
  $('#main').append('<div class="modal" id="call-dialog"><form><div class="field"><label for="id">Call ID</label><input type="text" id="id" style="width: 200px;" /><div class="instructions">Enter the ID of the call you want to view and press enter.</div></form></div>');
  $('#call-dialog').submit(function(){ document.location = "/calls/" + $('#call-dialog #id').attr('value'); return false;});

  $('#call-dialog').dialog({modal: true, title: 'Go to call', width: 400});
  $('#call-dialog #id').focus();
};

$APP.route_dialog = function() {
  $('.modal').dialog('close');
  $('#main').append('<div class="modal" id="route-dialog"><form><div class="field"><label for="id">Route ID</label><input type="text" id="id" style="width: 200px;" /><div class="instructions">Enter the ID of the route you want to view and press enter.</div></form></div>');
  $('#route-dialog').submit(function(){ document.location = "/routes/" + $('#route-dialog #id').attr('value'); return false;});
  $('#route-dialog').dialog({modal: true, title: 'Go to route', width: 400});
  $('#route-dialog #id').focus();
};

$APP.search = function() {
  let companyName = $('#company-name').val();

  $('.modal').dialog('close');
  $('#main').append('<div class="modal" id="search-dialog"><form action="/search"><input type="text" name="search[term]" id="search-term" style="width: 300px;" /><div class="instructions">Enter part or all of a tech\'s first or last name or a customer\'s last name, work order number, etc., or "ed 3/27" and press enter to search.</form><div class="results"></div></div>');
  $('#search-dialog').submit(function(){ document.location = "/search/?" + $('#search-dialog form').serialize(); return false;});
  $('#search-dialog form').submitWithAjax();
  $('#search-dialog').dialog({modal: true, title: 'Search ' + companyName, width: 450});
  $('#search-dialog #search[term]').focus();
};

$APP.error_handler = function(result) {
  response = result.responseText
  if( response['errors'] )
  {
    $('<div class="modal">' + response['errors'] + '</div>').dialog({modal:true});
  }
  else
  {
    $('<div class="modal">Something has gone wrong. Please contact support.</div>').dialog({modal:true});
  }
};

import 'v2/app.init';

//jquery.minicolors.simple_form
$(document).on(window.Turbolinks ? 'turbolinks:load' : 'ready', function() {
  $('[data-minicolors]').each(function(i, elem) {
    $(elem).minicolors($(elem).data('minicolors'));
  });
});

window._ = _;
window.$ = $;

import WebpackerReact from 'webpacker-react';
import MultiPictureApp from '../app/bundles/APP/startup/MultiPictureApp';
import ServiceApp from '../app/bundles/APP/startup/ServiceApp';
import Fact from '../app/bundles/APP/components/common/Fact';

WebpackerReact.setup({
  MultiPictureApp,
  ServiceApp,
  Fact
})

function atBeginningOfDay(date) {
  date.setHours(0, 0, 0, 0);
  return date
}

function toggle_print()
{
  $('.pictures').toggleClass('forceprint', $('#shouldForcePrint').is(':checked'))
}

function pad(pad_me, width, pad_element) {
  pad_element = pad_element || '0';
  pad_me = pad_me + '';
  return pad_me.length >= width ? pad_me : new Array(width - pad_me.length + 1).join(pad_element) + pad_me;
}

function startTimer(startTime) {
  var now = new Date();
  now.setTime(startTime * 3600000);
  setInterval(function () {
    $(".time-out").html(now.getUTCHours() + ":" + pad(now.getUTCMinutes(), 2));
    now.setTime(now.getTime() + 1000);
  }, 1000);
}

function create_heatmap() {
  let myLatlng = new google.maps.LatLng(36.07, -94.15);
  let myOptions = {
    zoom: 4,
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: false,
    scrollwheel: true,
    draggable: true,
    navigationControl: true,
    mapTypeControl: false,
    scaleControl: true,
    disableDoubleClickZoom: false
  };
  let map = new google.maps.Map($("#heatmapArea")[0], myOptions);
  /*let heatmap = new HeatmapOverlay(map, {
  "radius":20,
  "visible":true,
  "opacity":60
  });*/

  let max = $('#heatmap-max').val();
  let data = JSON.parse($('#heatmap-data').val());
  let testData = {
    max: max,
    data: data
  };
  // this is important, because if you set the data set too early, the latlng/pixel projection doesn't work
  google.maps.event.addListenerOnce(map, "idle", function(){
    var heatmap = new HeatmapOverlay(map, {
      "radius":20,
      "visible":true,
      "opacity":60
    });

    heatmap.setDataSet(testData);
  });
}


$(document).ready(function () {
  let appToken = $('#app-token').val();
  $APP.token = appToken;

  $("fieldset.employee-schedule-profile > input").checkboxradio({
    icon: false
  });

  $('.timeline.route.itinerary a').tooltip();

  $(document).on('click', '.report-parts-box-used', function(e) {
    var value = e.target.value;
    var form = $(e.target).closest('form');

    if (value === 'true') {
      $('.parts-items-container').removeClass('display-none');
      if ($('#parts-reports-items .nested-fields', form).length <= 0) {
        $('#parts-reports-items .add_fields', form).click();
      }
    } else {
      if ($('#parts-reports-items .remove_fields', form).length > 0) {
        if (confirm('This action will remove all the existent items. Do you want to continue?')) {
          $('#parts-reports-items .remove_fields', form).each(function (index, element) {
            $(element).click();
          });
          $('.parts-items-container', form).addClass('display-none');
        } else {
          e.preventDefault();
        }
      } else {
        $('.parts-items-container', form).addClass('display-none');
      }
    }
  });

  if ($('body.applicants.show').length > 0) {
    $('.completed-state').last().addClass('current-state');
  }

  $(document).on('click', 'body.roles.show #sections a', function (e) {
    e.preventDefault();
    $(this).tab('show');
  })

  $(document).on('click', '.printme', function () {
    window.print();
  })

  if ($('body.route_templates.new').length > 0) {
    const dayStart = atBeginningOfDay(new Date()).getTime();

    $('.route-template-datepicker').datepicker({
      autoclose: false,
      format: $('#route-template-format').val(),
      weekStart: 1,
      multidate: true,
      daysOfWeekDisabled: $('#days-of-week-disabled').val(),
      datesDisabled: $('#dates-disabled').val(),
      beforeShowDay: function(date) {
        const isPastDate = date.getTime() < dayStart;

        if (isPastDate) {
          return {
            enabled: false,
            classes: 'past-date'
          }
        }
      }
    });
  }

  $(document).on('change', '#shouldForcePrint', function () {
    toggle_print();
  });

  if ($('body.timelogs.status').length > 0) {
    let status = $('#timelog-status').val()

    if (status == 'out') {
      $.ajax({
        url: '/timelogs/status.json'
      }).done(function(data) {
        startTimer(data["time_off"]["hours_off"])
      })
    }
  }

  $(document).on('click', '.add-a-past_job', function () {
    $('.past_job-list').append($('.past_job_pattern .past_job').clone());
    return(false);
  });

  $(document).on('click', '.add-a-residence', function () {
    $('.residence-list').append($('.residence_pattern .residence').clone());
    return(false);
  });

  $(document).on('click', '.add-a-reference', function () {
    $('.reference-list').append($('.reference_pattern .reference').clone());
    return(false);
  });

  if ($('#close-window').length > 0) {
    window.close();
  }

  if ($('#print-now').length > 0) {
    window.print();
  }

  if($('.outstanding-reports').length > 0) {
    let appointmentId = $('#appointment-id').val();
    $.get("/appointments/" + appointmentId + "/tech_report/outstanding_reports", function (result) {
      $('.outstanding-reports').html(result);
    });
  }

  if ($("body.appointments-recommendations.new").length > 0) {
    $(document).on('ajax:beforeSend', 'form', function () {
      $('.recommendation-item form').addClass('display-none');
      $('.recommendation-loader').removeClass('display-none');
    })

    $(document).on('ajax:complete', 'form', function () {
      $('.recommendation-item').html($('.recommendation-next-item').html());
      $('.recommendation-next-item').html('');
      $('.recommendation-loader').addClass('display-none');
    })

    $(document).on('click', '.recommendation-open-link-action', function () {
      let link = $('.recommendation-link')[0].value;
      window.open(link, '_blank');
    })
  }

  if ($('body.reports.lead_time').length > 0) {
    let columns = JSON.parse($('#lead-time-columns').val());
    google.charts.load("current", { packages: ["corechart"] });

    function drawChart(columns) {
      var data = new google.visualization.DataTable();

      data.addColumn('number', 'Lead Time');
      data.addRows(columns);

      var options = {
        legend: { position: 'none' },
        histogram: { lastBucketPercentile: 5 },
      };

      var chart = new google.visualization.Histogram(document.getElementById('chart_div'));
      chart.draw(data, options);
    }

    google.charts.setOnLoadCallback(function() {
      drawChart(columns);
    });
  }

  if ($('body.data.heatmap').length > 0) {
    create_heatmap()
  }
})

$(document).on('change', '#timeline_date', function () {
  let t = "/timelines/" + $('#timeline_date').val();
  window.location = t;
});

import "packs/application.scss"
