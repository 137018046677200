var APP = APP || {}
APP.partsRequests = APP.partsRequests || {}

APP.partsRequests.functions = {
  //
  // Table functions
  //
  hideActions: function(elem) {
    var id = $(elem).attr('data-parts-request-id');
    var table = $(elem).closest('table');

    $('[data-parts-request-actions-row=' + id + ']', table).addClass('display-none');
    $(elem).html('Show Actions');
    $(elem).attr('data-parts-request-actions-opened', false);
  },

  showActions: function(elem) {
    var id = $(elem).attr('data-parts-request-id');
    var table = $(elem).closest('table');

    $('[data-parts-request-actions-row=' + id + ']', table).removeClass('display-none');
    $(elem).html('Hide Actions');
    $(elem).attr('data-parts-request-actions-opened', true);
  },
  //
  // Table functions
  //

  //
  // Batch functions
  //
  unselectAll: function () {
    $('[data-parts-request-actions="batch"]:visible:checked').click();
  },

  selectAll: function () {
    $('[data-parts-request-actions="batch"]:visible:not(:checked)').click();
  },

  updateSelectAllCheckbox: function () {
    var notChecked = $('[data-parts-request-actions="batch"]:visible:not(:checked)');

    if (notChecked.length == 0) {
      $('[data-parts-request-actions="select-all"]').prop('checked', true);
    } else {
      $('[data-parts-request-actions="select-all"]').prop('checked', false);
    }
  },

  updateCheckedQuantity: function () {
    var quantity = $('[data-parts-request-actions="batch"]:visible:checked').length;
    $('[data-parts-request-batch-quantity]').html(quantity);
  },

  updateBatchActions: function () {
    var checkeds = $('[data-parts-request-actions="batch"]:checked');
    var statuses = []

    checkeds.each(function(index, element) {
      var status = $(element).attr('data-status');
      if (!statuses.includes(status)) {
        statuses.push(status);
      }
    });

    $('[data-parts-request-batch-action-trigger]').each(function(index, element) {
      var checkboxStatuses = JSON.parse($(element).attr('data-parts-request-batch-action-allowed-by-statuses'));

      var validStatus = true;

      remainingStatuses = statuses.filter( ( el ) => !checkboxStatuses.includes( el ) );
      if (remainingStatuses.length > 0) {
        validStatus = false;
      }

      if (validStatus) {
        $(element).removeClass('display-none');
      } else {
        $(element).addClass('display-none');
      }
    });
  },

  updateBatchBoxVisibility: function () {
    var box = $('[data-parts-request-batch-box]');
    var checked = $('[data-parts-request-actions="batch"]:checked');
    var availableButtons = $(".actions button", box);

    if (availableButtons.length > 0 && checked.length > 0) {
      box.removeClass('display-none');
    } else {
      box.addClass('display-none');
    }
  }
  //
  // Batch functions
  //
}

$(document).ready(function () {
  $(document).on('change', '#parts-request-category', function (event) {
    var value = event.target.value;
    var role = null;

    if (value == "Review") {
      role = "Admin";

    } else if (value == "Route Request") {
      role = "Scheduler";
    }

    if (role) {
      var option = $('#parts-request-role option').filter(function () { return $(this).html() == role; }).val();
      $('#parts-request-role').val(option);
    }
  })

  $(document).on('click', '[data-parts-request-actions=true]', function (event) {
    event.preventDefault();
    var elem = event.target;

    $('[data-parts-request-actions-opened=true]').each(function(index, otherElem) {
      var id = $(elem).attr('data-parts-request-id');
      var otherId = $(otherElem).attr('data-parts-request-id');

      if (id != otherId) {
        APP.partsRequests.functions.hideActions(otherElem);
      }
    });

    if($(elem).attr('data-parts-request-actions-opened') == 'true') {
      APP.partsRequests.functions.hideActions(elem);
    } else {
      APP.partsRequests.functions.showActions(elem);
    }
  });

  $(document).on('ajax:beforeSend', '[data-parts-request-actions-row]', function (event) {
    $('.parts-request-alert').addClass('display-none');
    $('.parts-request-alert .message-body').html(null);
    $('.fixed-loader').removeClass('display-none');
  });

  $(document).on('ajax:error', '[data-parts-request-actions-row]', function (event, jqXHR) {
    errors = JSON.parse(jqXHR.responseText).errors;

    $.each(errors, function (idx, error) {
      var message = document.createElement('div');
      $(message).html(error);
      $('.parts-request-alert .message-body').append(message);
    })

    $('.parts-request-alert').removeClass('display-none');
    $('.fixed-loader').addClass('display-none');
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".parts-request-alert").offset().top - 100
    }, 1000);
  });

  $(document).on('ajax:success', '[data-parts-request-actions-row]', function (event) {
    var batch = $('[data-parts-request-actions-row]').attr('data-batch');

    // If the batch flag is set, then the system is doing a batch update.
    // In the last request, batch will be set as false, so this function can reload the list.
    if (batch !== 'true') {
      let container = $(event.target).closest('[data-parts-request-reload-url]');
      let url = container.data('parts-request-reload-url');

      $.ajax({
        url: url,
        data: {
          format: 'js'
        }
      }).done(function () {
        APP.partsRequests.functions.updateBatchBoxVisibility();
        $('.fixed-loader').addClass('display-none');
      })
    }
  });
});

$(document).ready(function () {
  $(document).on('click', '[data-parts-request-actions=batch]', function (event) {
    var target = $(event.target);

    APP.partsRequests.functions.updateSelectAllCheckbox();
    APP.partsRequests.functions.updateBatchActions();

    APP.partsRequests.functions.updateCheckedQuantity();
    APP.partsRequests.functions.updateBatchBoxVisibility();
  });

  $(document).on('click', '[data-parts-request-actions="select-all"]', function (event) {
    var target = $(event.target);

    if (target.prop('checked')) {
      APP.partsRequests.functions.selectAll();
    } else {
      APP.partsRequests.functions.unselectAll()
    }
  })

  $(document).on('click', '[data-parts-request-batch-action-trigger]', function (event) {
    var target = $(event.target);
    var confirmMessage = $(target).attr('data-confirm-message');

    if (!confirmMessage || confirm(confirmMessage)) {
      var method = $(target).attr('data-parts-request-batch-action-method');
      var checkeds = $('[data-parts-request-actions="batch"]:checked');

      // Setting this attibute to avoid reloading the list befor all updates have been finished.
      $('[data-parts-request-actions-row]').attr('data-batch', true);
      $('.fixed-loader').removeClass('display-none');

      checkeds.each(function (index, element) {
        var row = $(element).closest('[data-parts-request-row]');
        var id = row.attr('data-parts-request-row');
        var actions = $('[data-parts-request-actions-row="' + id + '"]');
        var button = $('[data-parts-request-method="' + method + '"]', actions);

        if (index == checkeds.length - 1) {
          $('[data-parts-request-actions-row]').attr('data-batch', false);
        }

        button.data('confirm', null);
        button.click();
      })
    }
  });
});

//
// Batch
//
