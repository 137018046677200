angular.module('APP.services', []).
service('SchedulerService', ['$rootScope', '$timeout', 'fixRailsSerializer', 'Activity', 'Assignment', 'AssignmentNote', 'Calendar', '$filter', '$window', function ($rootScope, $timeout, fix, Activity, Assignment, AssignmentNote, Calendar, $filter, $window)
{
  var scope = $rootScope.$new();

  Activity.query().then(function(resource)
  {
    scope.activity_list = resource.activities;
  });

  return(
  {
    clear: function()
    {
      scope.active_schedule = null;
      scope.active_assignment = null;
    },

    clear_assignment: function()
    {
      scope.active_assignment = null;
    },

    initialize_note: function()
    {

    },

    edit_schedule: function ( input )
    {
      scope.active_schedule = input;
      scope.active_assignment = null;

      if( scope.active_schedule.note == "note" || scope.active_schedule.note === undefined )
      {
        scope.active_schedule.note = new AssignmentNote({text: ' ', employee_id: scope.active_schedule.employee_id, date: $filter('date')(scope.active_schedule.date, 'yyyy-MM-dd')});
      }
    },

    edit_assignment: function( input )
    {
      scope.active_assignment = input;
    },

    new_assignment: function( input )
    {
      var assignment = new Assignment(
      {
        activity_type: 'Activity',
  	    activity_id: '',
        employee_id: scope.active_schedule.employee_id,
        date: $filter('date')(scope.active_schedule.date, 'yyyy-MM-dd')
      });

      scope.active_assignment = assignment;
    },

    add_assignment: function( assignment )
    {
      scope.active_schedule.assignments.push( assignment );
    },

    delete_assignment: function( assignment )
    {
      if( assignment.activity_type == "UnmanagedRoute" )
      {
        assignment.$delete("/unmanaged_routes/" + assignment.activity_id).then(function () {
          var i = scope.active_schedule.assignments.indexOf(assignment);
          scope.active_schedule.assignments.splice(i, 1);
        });
      }
      else
      {
        assignment.delete().then(function () {
          var i = scope.active_schedule.assignments.indexOf(assignment);
          scope.active_schedule.assignments.splice(i, 1);
        });

      }
    },

    new_unmanaged_route: function(team)
    {
      var assignment = new Assignment(
      {
         activity_type: 'UnmanagedRoute',
         activity_id: null,
         employee_id: scope.active_schedule.employee_id,
         team: team,
         date: $filter('date')(scope.active_schedule.date, 'yyyy-MM-dd')
      });

      scope.active_assignment = assignment;
    },

    save_note: function()
    {
      if (scope.active_schedule.note.id === undefined) {
        scope.active_schedule.note.create().then(function(n) {
          scope.active_schedule.note.id = n.id;
	      })
      } else {
        if (scope.active_schedule.note.timeout) {
          $timeout.cancel(scope.active_schedule.note.timeout);
        }

        var note = scope.active_schedule.note;
        note.timeout = $timeout(function() {
          note.update();
        }, 700);
      }
    },

    schedule: function (){
      return(scope.active_schedule);
    },

    assignment: function()
    {
      return(scope.active_assignment);
    },

    schedule_has_unmanaged_route: function()
    {
      var out = false;

      for(var i =0; i < scope.active_schedule.assignments.length; i++) {
        if('UnmanagedRoute' == scope.active_schedule.assignments[i].activity_type) {
          out = true;
        }
      }

      return(out);
    },

    schedule_has_route: function()
    {
      out = false;

      scope.active_schedule && angular.forEach( scope.active_schedule.assignments, function(assignment)
      {
        if( assignment.activity_type == 'Route' )
        {
          out = true;
        }
      });

      return( out );
    },

    describe: function( assignment )
    {
      if(assignment.activity_type == 'Route') {
        return('R' + assignment.activity_id);

      } else if(assignment.activity_type == 'UnmanagedRoute') {
        return(assignment.description);

      } else if(assignment.activity_type == 'Activity') {
      	if(assignment.description > "") {
          return(assignment.description);

      	} else {
          angular.forEach(scope.activity_list, function(activity) {
            if(activity.id == assignment.activity_id) {
              assignment.description = activity.name;
            }
          })
      	  return(assignment.description);
      	}

      } else {
        throw "I do not know";
      }
    },

    activity_list: function()
    {
      return( scope.activity_list );
    }
  });
}]);
