function setDepartureInfo() {
  let option = $('#departure_presenter_next_appointment_id option:selected');

  let timeframe = $(option).data('eta');
  let runningLate = $(option).data('running-late');
  let suggestedTimeframe = $(option).data('suggested-timeframe');

  $('.departing-page-next-timeframe').html(timeframe);
  $('.departing-page-next-running-late').html(runningLate);

  let newTimeframe = $('#departure_presenter_eta option').filter(function () {
    return $(this).html() == suggestedTimeframe;
  }).val();

  $('#departure_presenter_eta').val(newTimeframe);
}

$(document).on('change', '#departure_presenter_next_appointment_id', function () {
  setDepartureInfo();
})


$(document).ready(function () {
  setDepartureInfo();
})
