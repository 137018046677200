app.controller('NewRouteCtrl', [
  '$scope', '$routeParams', '$location', 'Technician', 'Route', 'Calendar',
  function($scope, $routeParams, $location, Technician, Route, Calendar ) {
    $scope.page_header = "New Route";

    function initialize()
    {
      $scope.route = {};
      $scope.route.start_time = $routeParams['start_time'] || '7:00';
      $scope.route.max_time = $routeParams['max_time'] || '9:00';
      $scope.route.date = $routeParams['date'];
      $(document).on('app.api_error', handleApiErrors.bind($scope));
    };

    function handleApiErrors(_event, payload) {
      const scope = this;
      scope.route.has_overridable_errors = payload.data.has_overridable_errors;
    }

    initialize();

    function set_technician()
    {
      angular.forEach( $scope.technicians, function( tech )
      {
        if( tech.id == $routeParams['technician_id'] )
        {
          $scope.route.technician = tech;
          $scope.change_technician();
        }
      })
    }

    Technician.query().then(
      function(resources)
      {
        $scope.technicians = resources;
        set_technician();
      }
    );

    $scope.custom_origin = {description: 'Custom'};
    $scope.custom_destination = {description: 'Custom'};

    $scope.change_technician = function()
    {
      if( $scope.route.technician !== undefined && $scope.route.technician !== null )
      {
        $scope.origin_options = $scope.route.technician.locations.concat([$scope.custom_origin]);

        $scope.destination_options = $scope.route.technician.locations.concat([$scope.custom_destination]);

        $scope.route.origin = $scope.origin_options[0];
        $scope.route.destination = $scope.destination_options[0];

        $scope.updateRouteTimes();
        $scope.updateRouteFlags();
      }
    }

    $scope.$watch('route.date', function(newValue, _oldValue){
      if (newValue) {
        $scope.updateRouteTimes();
      }
    });

    $scope.updateRouteTimes = function() {
      const route = $scope.route,
        technician = route.technician;

      if (technician == undefined) {
        return;
      }

      let startTime = technician.startTimeTimepicker,
          maxTime = technician.maxTime;

      const dateString = route.date, // in format 'yyy-mm-dd'
        scheduleProfile = technician.schedule_profile || technician.scheduleProfile;

      if (dateString && scheduleProfile) {
        const [year, month, day] = dateString.split(/[-\/]/),
          routeDate = new Date(year, month - 1, day),          // month '0' is january
          routeWeekDay = routeDate.getDay(),
          schedule = scheduleProfile.attributes.schedule,
          routeDaysSchedule = schedule[routeWeekDay];

        if (routeDaysSchedule) {
          startTime = routeDaysSchedule.startTime;
          maxTime = routeDaysSchedule.maxTime;
        }
      }

      $scope.route.start_time = startTime;
      $scope.route.max_time = maxTime;
    }

    $scope.updateRouteFlags = function() {
      const route = $scope.route;
      const technician = route.technician;

      let techManagedRoute = false;
      let skipAutoConfirmationProcess = false;

      if (technician != undefined) {
        techManagedRoute = technician.techManagedRoutes || technician.tech_managed_routes;
        skipAutoConfirmationProcess = technician.skipAutoConfirmationProcess || technician.skip_auto_confirmation_process;
      }

      $scope.route.tech_managed = techManagedRoute;
      $scope.route.skip_auto_confirmation_process = skipAutoConfirmationProcess;
    }

    $scope.persist = function()
    {
      function visit_route_page(response)
      {
        window.location = "/routes/" + response.id;
      }

      var route = new Route({
        technician_id: $scope.route.technician.id,
        date: $scope.route.date,
        start_time: $scope.route.start_time,
        max_time: $scope.route.max_time,
        override_validation: $scope.route.override_validation,
        validation_override_reason: $scope.route.validation_override_reason,
        tech_managed: $scope.route.tech_managed,
        skip_auto_confirmation_process: $scope.route.skip_auto_confirmation_process
      });

      if( $scope.route.origin.description == "Custom" )
      {
        route.origin_attributes =
        {
          street1: $scope.route.origin.street1,
        	street2: $scope.route.origin.street2,
        	city: $scope.route.origin.city,
        	state: $scope.route.origin.state,
        	zipcode: $scope.route.origin.zipcode
        }
      }
      else
      {
        route.origin_id = $scope.route.origin.locationId || $scope.route.origin.location_id;
      }

      if( $scope.route.destination.description == "Custom" )
      {
        route.destination_attributes =
        {
          street1: $scope.route.destination.street1,
        	street2: $scope.route.destination.street2,
        	city: $scope.route.destination.city,
        	state: $scope.route.destination.state,
        	zipcode: $scope.route.destination.zipcode
        }
      }
      else
      {
        route.destination_id = $scope.route.destination.locationId || $scope.route.destination.location_id;
      }

      route.create().then( visit_route_page )
    }

    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];

  }
]);
